<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="6">
        <h1 class="mt-10  ">Bases</h1>
      </v-col>
      <v-col cols="6" class="mt-10" v-if="this.formatosId">
        <CheckButtons
            :tenderId="this.tenderId"
            :formatosId="this.formatosId"
        />
      </v-col>
      <v-col cols="12">
        <hr class="">
      </v-col>

      <v-col cols="12">
        <v-tabs
            background-color="primary"
            dark
        >
          <v-tab href="#form">
            Bases 3.0
          </v-tab>
          <v-tab-item value="form">
            <v-form ref="form" class="mt-10" @submit.prevent="submitForm">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Calendario</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Calendario</h1>
                        <trumbowyg
                            v-model="data.calendario"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Calendario"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Especificaciones Técnicas</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Especificaciones Técnicas</h1>
                        <trumbowyg
                            v-model="data.especificaciones_tecnicas"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Especificaciones Técnicas"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Visita guiada</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Visita guiada</h1>
                        <trumbowyg
                            v-model="data.visita_guiada"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Visita guiada"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-col cols="6" class="mt-6">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="mt-6">
                  <v-btn @click="generateDataBases" color="primary" style="width: 400px">
                    Generar Archivo
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-5">
                <v-col cols="3">
                  <v-btn type="button" v-if="documentGenerateStatus" @click="downloadFileServerBases" color="primary"
                         style="width: 200px">
                    Descargar archivo
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="documentGenerateStatus">
                  <h1 class="mt-4 mx-2 ">Visualización de documento
                  </h1>
                  <trumbowyg
                      v-model="documentGenerate"
                      disabled="disabled"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Visualización de documento"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab href="#formConstancia" @click="chargeDataPublic">
            Constancia publicación 3.1
          </v-tab>
          <v-tab-item value="formConstancia">
            <v-form ref="formConstancia" class="mt-10" @submit.prevent="submitFormConstancia">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Horas y Minutos</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Horas y Minutos</h1>
                        <trumbowyg
                            v-model="constancy.hora_minutos"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Horas y Minutos"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Fecha en Letra</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Fecha en Letra</h1>
                        <trumbowyg
                            v-model="constancy.fecha_letra"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Fecha en Letra"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Link de Publicación</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Link de Publicación</h1>
                        <trumbowyg
                            v-model="constancy.link_publicacion"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Link de Publicación"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-col cols="6" class="mt-6">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
                <v-col v-if="!createCb" cols="6" class="mt-6">
                  <v-btn @click="generateDataCB" color="primary" style="width: 400px">
                    Generar Archivo
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-5">
                <v-col cols="3">
                  <v-btn type="button" v-if="documentGenerateStatusCb" @click="downloadFileServerCB" color="primary"
                         style="width: 200px">
                    Descargar archivo
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="documentGenerateStatusCb">
                  <h1 class="mt-4 mx-2 ">Visualización de documento
                  </h1>
                  <trumbowyg
                      v-model="documentGenerateCb"
                      disabled="disabled"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Visualización de documento"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab href="#fileConstancy" @click="chargeDataFile">
            Constancia publicación 3.2
          </v-tab>
          <v-tab-item value="fileConstancy">
            <v-form v-if="createCbFile" ref="form" class="mt-10" @submit.prevent="submitFormFileCreate">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel>
                    <v-expansion-panel-header>Adjunta Documento</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <FileInput
                            :tenderId="data.tenderId"
                            :formatosId="data.formatosId"
                            :file="data.url"
                            @getImageFile="handleImageFile"
                            @changeShowImage="handleShowImage"
                        />
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-col cols="12" class="mt-4">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-form v-else ref="form" class="mt-10" @submit.prevent="submitFormFileEdit">
              <v-row class="mb-4" v-if="chargeData">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Adjunta Documento</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <FileInputEdit
                            :tenderId="dataConstancy.tenderId"
                            :formatosId="dataConstancy.formatosId"
                            :file="dataConstancy.url"
                            :file-tmp="fileTmp"
                            @getImageFile="handleImageFileUpdate"
                            @changeShowImage="handleShowImageUpdate"
                        />
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-col v-if="fileTmp" cols="6" class="mt-6">
                  <v-btn @click="downloadImage" type="button" color="primary" style="width: 200px">
                    Descargar archivo
                  </v-btn>
                </v-col>
                <v-col v-if="!fileTmp" cols="6" class="mt-6">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs>
      </v-col>


    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import CheckButtons from "../../components/CheckButtons";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'
import FileInput from "@/components/FileInput.vue";
import FileInputEdit from "@/components/FileInputEdit.vue";
import {URL_API_IMAGES} from "@/store/config";

export default {
  name: "DetailBases",
  data() {
    return {
      overlay: false,
      tenderId: null,
      externalId: null,
      fileTmp: '',
      fileSend: '',
      documentComplete: null,
      documentGenerate: null,
      documentGenerateCb: null,
      documentGenerateStatus: false,
      documentGenerateStatusCb: false,
      createCb: false,
      chargeData: false,
      id: null,
      idConstancyBase: null,
      idConstancyBaseFile: null,
      formatosId: null,
      data: {
        calendario: "",
        especificaciones_tecnicas: "",
        hora_minutos: "",
        visita_guiada: "",
        fecha_letra: "",
        link_publicacion: ""
      },
      constancy: {
        hora_minutos: "",
        link_publicacion: "",
        fecha_letra: "",
        formatId: 18,
        tenderId: null
      },
      createCbFile: true,
      dataConstancy: {
        tenderId: null,
        formatosId: 19,
        url: '',
      },
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
    };
  },
  components: {
    FileInputEdit,
    FileInput,
    CheckButtons,
    Overlay,
    Trumbowyg
  },
  async created() {
    this.tenderId = parseInt(this.$route.params.id);
    this.id = parseInt(this.$route.params.marketId);
    await this.findMarketOne();
  },
  methods: {
    ...mapActions('bases', ['updateBases', 'findOneBases', 'generateHtmlFormatBases', 'downloadFileBases']),
    ...mapActions('constancy_bases', ['updateConstancyBases', 'findOneConstancyBases', 'generateHtmlFormatCB', 'downloadFileCB', 'findOneByTender', 'createConstancyBases', 'createConstancyBasesFile', 'findOneByTenderFile','updateBasesFile']),
    ...mapActions('tenders', ['findOneTenders']),
    handleImageFileUpdate(file) {
      this.dataConstancy.url = file;
      this.fileSend = file;
      this.fileTmp = '';
    },

    handleShowImageUpdate(show) {
    },
    async chargeDataPublic() {
      const response = await this.findOneByTender(this.tenderId);
      if (response) {
        this.createCb = false;
        this.idConstancyBase = response.id;
        this.constancy.hora_minutos = response.hora_minutos;
        this.constancy.link_publicacion = response.link_publicacion;
        this.constancy.fecha_letra = response.fecha_letra;
        this.constancy.formatId = response.formatosId;
        this.constancy.tenderId = response.licitacionesId;

      } else {
        this.constancy.tenderId = this.tenderId;
        this.createCb = true;
      }

    },
    async chargeDataFile() {
      this.chargeData = false;
      const response = await this.findOneByTenderFile(this.tenderId);
      this.dataConstancy.url = '';
      this.dataConstancy.tenderId = null;
      this.fileTmp = '';
      this.fileSend = '';
      if (response) {
        this.createCbFile = false;
        this.idConstancyBaseFile = response.id;
        this.dataConstancy.url = response.url;
        this.dataConstancy.tenderId = response.licitacionesId;
        this.dataConstancy.formatosId = response.formatosId;
        this.fileTmp = response.url;
      } else {
        this.dataConstancy.tenderId = this.tenderId;
        this.createCbFile = true;
      }
      this.chargeData = true;
    },
    handleImageFile(file) {
      this.dataConstancy.url = file;
    },
    handleShowImage(show) {
    },
    async findMarketOne() {
      const response = await this.findOneBases({id: this.id, tenderId: this.tenderId});
      if (response) {
        this.documentComplete = response.documentoCompleto;
        this.data.formatData = response.formatoData;
        this.data.calendario = response.calendario;
        this.data.especificaciones_tecnicas = response.especificaciones_tecnicas;
        this.data.hora_minutos = response.hora_minutos;
        this.data.fecha_letra = response.fecha_letra;
        this.data.link_publicacion = response.link_publicacion;
        this.data.visita_guiada = response.visita_guiada;

        this.formatosId = response.formatosId;
        const responseExternal = await this.findOneTenders(response.licitacionesId);
        if (responseExternal) {
          this.externalId = responseExternal.claveLicitacion;
        } else {
          this.externalId = null;
        }
      } else {
        await this.$router.push(`/licitaciones_documentos/${this.tenderId}`)
      }
    },
    async validateRequired() {
      if (!this.data.calendario) {
        this.sweetAlertWarning('Campo requerimiento necesario');
        return false
      }
      if (!this.data.especificaciones_tecnicas) {
        this.sweetAlertWarning('Campo especificacions de servicio requerido');
        return false
      }

      return true

    },
    async validateRequiredConstancia() {
      if (!this.constancy.hora_minutos) {
        this.sweetAlertWarning('Campo Hora y Minutos necesario');
        return false
      }
      if (!this.constancy.fecha_letra) {
        this.sweetAlertWarning('Campo Fecha en Letra requerido');
        return false
      }
      if (!this.constancy.link_publicacion) {
        this.sweetAlertWarning('Campo Link de Publicación requerido');
        return false
      }

      return true

    },
    async submitForm() {
      const validateData = await this.validateRequired();
      if (!validateData) {
        return
      }
      this.overlay = true;
      const response = await this.updateBases({id: this.id, data: this.data});
      if (!response.error) {
        await this.findMarketOne();
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async submitFormConstancia() {
      const validateData = await this.validateRequiredConstancia();
      if (!validateData) {
        return
      }
      this.overlay = true;
      if (this.createCb) {
        const response = await this.createConstancyBases(this.constancy);
        if (!response.error) {
          await this.chargeDataPublic();
          this.sweetAlertSuccess('Se creo correctamente')
        } else {
          this.sweetAlertError('Error al crear')
        }
      } else {
        const response = await this.updateConstancyBases({id: this.idConstancyBase, data: this.constancy});
        if (!response.error) {
          await this.chargeDataPublic();
          this.sweetAlertSuccess('Se edito correctamente')
        } else {
          this.sweetAlertError(response.error)
        }
      }

      this.overlay = false;
    },

    async generateDataBases() {
      this.overlay = true;
      const response = await this.generateHtmlFormatBases({id: this.id});
      if (!response.error) {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
      } else {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },

    async downloadFileServerBases() {
      this.overlay = true;
      await this.downloadFileBases({id: this.id, externalId: this.externalId});
      this.overlay = false;
    },
    async validateRequiredFile() {
      if (!this.dataConstancy.url) {
        this.sweetAlertWarning('Campo Documento es necesario');
        return false
      }

      return true

    },
    async submitFormFileCreate() {
      const validateData = await this.validateRequiredFile();

      if (!validateData) {
        return
      }
      this.overlay = true;

      const response = await this.createConstancyBasesFile(this.dataConstancy);
      if (!response.error) {
        await this.chargeDataFile();
        this.sweetAlertSuccess('Se creo correctamente')
      } else {
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },

    async submitFormFileEdit() {
      const validateData = await this.validateRequiredFile();

      if (!validateData) {
        return
      }
      this.overlay = true;
      const response = await this.updateBasesFile({id: this.idConstancyBaseFile, data: this.fileSend});
      if (!response.error) {
        await this.chargeDataFile();
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError('Error al editar')
      }
      this.overlay = false;
    },

    async generateDataCB() {
      this.overlay = true;
      const response = await this.generateHtmlFormatCB({id: this.idConstancyBase});
      if (!response.error) {
        this.documentGenerateCb = response;
        this.documentGenerateStatusCb = true;
      } else {
        this.documentGenerateCb = response;
        this.documentGenerateStatusCb = false;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },

    async downloadFileServerCB() {
      this.overlay = true;
      await this.downloadFileCB({id: this.idConstancyBase, externalId: this.externalId});
      this.overlay = false;
    },
    async downloadImage() {
      try {
        const url = `${URL_API_IMAGES}${this.dataConstancy.url}`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Error al descargar el archivo');
        }

        const blob = await response.blob();
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'archivo';

        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          const matches = /filename="([^"]*)"/.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1];
          }
        } else {
          const urlParts = url.split('/');
          fileName = urlParts[urlParts.length - 1];
        }

        // Crea un enlace temporal para descargar el archivo
        const urlBlob = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error al descargar el archivo', error);
      }
    },
    alertNexPrograming() {
      this.sweetAlertWarning('Proximamente')
    }
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
